<template>
    <div class="PlantCountQA">
        <div 
            id="image-info" 
            class="header-info-container"
            v-if="analytic && image"
        >
            <h2
                :title="analytic.field_name + ' / ' + image.name"
                style="flex: 5;"
            >{{ trimText(analytic.field_name) }} / {{ trimText(image.name) }}</h2>
            <h2>Subimage {{ subimage_idx + 1 }} / {{ n_subimages }}</h2>
            <h2>{{ qa_status }}</h2>
        </div>
        <div id="container" class="ulabel-container"/>
    </div>
</template>

<script>
import { RouteNames, ULABEL_SUBTASK_IDS } from "../utils/constants.js";
import { 
    gendered_row_qa_subtask,
    get_config_data, 
    get_slider_values_from_ulabel_html,
    ppa_qa_subtask,
    set_slider_values_in_ulabel_html,
    subimage_bounds_subtask 
} from "../utils/ulabel_utils.js";
import { getMeta } from "../utils/qa_utils.js";
import { ULabel } from "ulabel"
import { get_username } from "../utils/qa_utils.js";
import { get_subimage_crop_from_idx, get_ulabel_initial_crop_from_bbox, make_ulabel_bbox } from "../utils/ulabel_anno_utils.js";
import { isInternalUser } from "../utils/cognito.js";
import { hide_navbar, show_navbar, get_key_from_route_params, get_key_from_query_params, parseBool, navigateToRoute } from "../utils/nav_utils";
import { trimText } from "../utils/qa_utils";
import { getAnalytic, getImage, getNextImage, patchSubimage, getNextOutsourcedImage, unlockImage, saveAnnotations, updateQAStatus, lockImage } from "../utils/api_endpoints.js";

export default {
    data() {
        return {
            analytic: null,
            image: null,
            qa_status: "loading...",
            n_subimages: 9,
            subimage_idx: 0,
            ulabel: null,
            rgb_displayed: true,
            approve_all_subimages: false,
            opened_as_new_tab: false,
            isInternalUser: isInternalUser,
        };
    },
    async mounted() {
        // Hide the entire navbar to force using the buttons
        hide_navbar();

        let next_image_found = false;
        if (isInternalUser()) {
            // Get field info from route params
            let analytic_id = get_key_from_route_params(this, "analytic_id");
            this.analytic = await getAnalytic(analytic_id);

            if (this.analytic.rowfill) {
                alert("PPA QA isn't allowed for rowfill fields");
                this.navigate_away();
                return;
            }

            let image_id = get_key_from_query_params(this, "image_id");
            this.opened_as_new_tab = parseBool(get_key_from_query_params(this, "new_tab"));

            if (image_id !== null) {
                this.image = await getImage(image_id, false, true);
                if (!await lockImage(this.image.id)) {
                    alert("Image is locked by another user");
                    this.navigate_away();
                    return;
                }
                next_image_found = true;

                this.image.subimages.forEach(subimage => {
                    subimage.status = subimage.rejected ? "rejected" : "not_done"
                })
            }
        }

        if (!next_image_found) {
            // If no image_id is in the query params, we get the next available image
            next_image_found = await this.load_next_image();
        }

        if (next_image_found) {
            document.addEventListener("keydown", this.keydownEventHandler);
            await this.load_and_run_all();
        }
    },
    beforeDestroy() {
        this.beforeDestoryHandler();
    },
    methods: {
        trimText,
        keydownEventHandler(e) {
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    this.ulabel.swap_frame_image(this.image.binary_url);
                } else {
                    this.ulabel.swap_frame_image(this.image.rgb_url);
                }
                this.rgb_displayed = !this.rgb_displayed;
            }
        },
        navigate_away() {
            this.beforeDestoryHandler();

            if (this.opened_as_new_tab) {
                // Close new tab, no need to navigate
                return window.close();
            }

            if (isInternalUser()) {
                navigateToRoute(this, RouteNames.AnalyticDetails, this.analytic.id.toString())
            } else {
                navigateToRoute(this, RouteNames.Home)
            }
        },
        beforeDestoryHandler() {
            // Remove event listeners (including ULabel's)
            document.removeEventListener("keydown", this.keydownEventHandler);
            if (this.ulabel) {
                this.ulabel.remove_listeners();
            }
            // Un-hide the navbar
            show_navbar();
        },
        async go_without_saving(idx_change) {
            // Navigate to next/previous subimage
            let new_subimage_idx = this.subimage_idx + idx_change;

            // Prevent out of bounds navigation
            if (new_subimage_idx >= 0 && new_subimage_idx < this.n_subimages) {
                this.subimage_idx = new_subimage_idx;
                await this.reload_ulabel();
            }
        },
        update_qa_status() {
            this.qa_status = this.image.subimages[this.subimage_idx]["status"];
        },

        /**
         * Load the next image to QA
         * 
         * @returns {Promise<boolean>} Whether the next image was loaded successfully
         */
        async load_next_image() {
            if (this.image) {
                // If an image is loaded, we need to unlock before loading next
                await unlockImage(this.image.id);
            }

            if (isInternalUser()) {
                this.image = await getNextImage(this.analytic.id, "ppa_qa")
            } else {
                // External users only get outsourced images
                this.image = await getNextOutsourcedImage()
                // Have to load analytic as well
                if (this.image) {
                    this.analytic = await getAnalytic(this.image.analytic_id)
                }
            }

            if (this.image === null) {
                alert("No Images Left to QA")
            }

            if (this.image === null || this.opened_as_new_tab) {
                this.navigate_away();
                return false;
            }

            if (!await lockImage(this.image.id)) {
                alert("Image is locked by another user");
                this.navigate_away();
                return false;
            }

            this.image.subimages.forEach(subimage => {
                subimage.status = subimage.rejected ? "rejected" : "not_done"
            })
            this.subimage_idx = -1;
            this.subimage_idx = this.next_incomplete_subimage();
            
            // Guard against all subimages rejected
            if (this.subimage_idx >= this.n_subimages) {
                this.subimage_idx = 0;
            }
            return true
        },

        /**
         * Load all data and run ULabel
         * 
         */
        async load_and_run_all(reload_ulabel = false) {
            if (reload_ulabel) {
                // Set new image
                // Hack to load rgb and annotations at the same time
                await getMeta(this.image.rgb_url);
                this.ulabel.swap_frame_image(this.image.rgb_url);
                this.rgb_displayed = true;
                // Set new annotations
                this.ulabel.set_annotations(this.preproc_annotations(this.image.keypoint_annotations), ULABEL_SUBTASK_IDS.PLANT_COUNT_QA);
                this.ulabel.set_annotations(this.image.row_annotations, ULABEL_SUBTASK_IDS.ROW_QA);
                // Set new slider values
                set_slider_values_in_ulabel_html(this.image, this.analytic);
                await this.reload_ulabel();
            } else {
                // Start ULabel
                this.startULabel();
            }
        },

        /**
         * Reload ULabel with a new subimage
         */
        async reload_ulabel() {
            this.update_qa_status();
            let subimage_crop = get_subimage_crop_from_idx(
                this.image.width,
                this.image.height,
                this.subimage_idx,
                this.analytic.image_buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)

            // Update ULabel with new subimage bounds
            this.ulabel.set_annotations([subimage_anno], "subimage_bounds");
            this.ulabel["config"]["initial_crop"] = ulabel_initial_crop;
            this.ulabel.show_initial_crop();
        },

        read_size_cookie() {
            let subtask_name = ULABEL_SUBTASK_IDS.PLANT_COUNT_QA
            let cookie_name = subtask_name + "_size=";
            let cookie_array = document.cookie.split(";");
            for (let i = 0; i < cookie_array.length; i++) {
                let current_cookie = cookie_array[i];
                //while there's whitespace at the front of the cookie, loop through and remove it
                while (current_cookie.charAt(0) == " ") {
                    current_cookie = current_cookie.substring(1);
                }
                if (current_cookie.indexOf(cookie_name) == 0) {
                    return parseFloat(current_cookie.substring(cookie_name.length, current_cookie.length))
                }
            }
            return null
        },
        async on_submit(annotations) {
            let slider_values = get_slider_values_from_ulabel_html();

            annotations = this.preproc_annotations(annotations);
            let keypoints = {
                annotations: {
                    main: [annotations["annotations"][ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]],
                },
            }
            let rows = null
            if (isInternalUser()){
                rows = {
                    "row_annotations": annotations["annotations"][ULABEL_SUBTASK_IDS.ROW_QA]
                }
            }
            await saveAnnotations(
                this.image.id, 
                slider_values.keypoint_confidence_slider_val, 
                slider_values.row_dist_slider_val,
                keypoints,
                rows,
            );

            // Mark subimage not rejected in case approving previously rejected subimage
            if (!this.approve_all_subimages) {
                await patchSubimage(this.image.id, this.subimage_idx, false)
            }

            // Prepare to navigate to next job.
            this.image.subimages[this.subimage_idx]["status"] = "completed";
            await this.loadNextSubimage();
        },
        async loadNextSubimage() {
            let next_idx = this.next_incomplete_subimage();
            if (next_idx >= this.n_subimages || this.approve_all_subimages) {
                await updateQAStatus(this.image.id, "ppa_qa_status", "completed");

                this.approve_all_subimages = false;

                if (await this.load_next_image()) {
                    // Navigate to next image
                    await this.load_and_run_all(true);
                }
            } else {
                // Navigate to next subimage
                this.subimage_idx = next_idx;
                await this.reload_ulabel();
            }
        },
        async approveAllSubimages(annotations) {
            this.approve_all_subimages = true;
            await this.on_submit(annotations);
        },
        async exitWithoutSaving() {
            // Unlock image and navigate away
            await unlockImage(this.image.id);
            this.navigate_away();
        },
        async rejectAndContinue() {
            await patchSubimage(this.image.id, this.subimage_idx, true);
            this.image.subimages[this.subimage_idx]["status"] = "rejected";
            await this.loadNextSubimage();
        },
        async nextSubimage() {
            await this.go_without_saving(1);
        },
        async previousSubimage() {
            await this.go_without_saving(-1);
        },
        getSubmitButtons() {
            let args = {
                "set_saved": true,
                "size_factor": 0.8,
            }
            return [
                {
                    "name": "Previous Subimage",
                    "hook": this.previousSubimage,
                    "color": "gray",
                    "row_number": 0,
                    ...args,
                },
                {
                    "name": "Next Subimage",
                    "hook": this.nextSubimage,
                    "color": "lightgray",
                    "row_number": 0,
                    ...args,
                },
                {
                    "name": "Exit",
                    "hook": this.exitWithoutSaving,
                    "color": "khaki",
                    "row_number": 0,
                    ...args,
                },
                {
                    "name": "Approve",
                    "hook": this.on_submit,
                    "color": "lightgreen",
                    "row_number": 1,
                    ...args,
                },
                {
                    "name": "Approve All",
                    "hook": this.approveAllSubimages,
                    "color": "lightblue",
                    "row_number": 1,
                    ...args,
                },
                {
                    "name": "Reject",
                    "hook": this.rejectAndContinue,
                    "color": "pink",
                    "row_number": 1,
                    ...args,
                },
            ];
        },
        next_incomplete_subimage() {
            // Search remaining subimages first
            for (let next_idx = this.subimage_idx + 1; next_idx < this.n_subimages; next_idx++) {
                if (this.image.subimages[next_idx]["status"] == "not_done") {
                    return next_idx;
                }
            }

            // Go back to start in case we skipped any
            for (let next_idx = 0; next_idx < this.subimage_idx; next_idx++) {
                if (this.image.subimages[next_idx]["status"] == "not_done") {
                    return next_idx;
                }
            }
            return this.n_subimages;
        },
        preproc_annotations(annotations) {
            let anno_size = this.read_size_cookie();
            for (var i = 0; i < annotations.length; i++) {
                annotations[i].line_size = anno_size;
            }
            return annotations;
        },
        startULabel() {
            this.update_qa_status();

            let annos = this.preproc_annotations(this.image.keypoint_annotations);

            let subimage_crop = get_subimage_crop_from_idx(
                this.image.width,
                this.image.height,
                this.subimage_idx,
                this.analytic.image_buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)
            let subtasks = {
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(annos),
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(
                    this.image.row_annotations,
                    {
                        inactive_opacity: 0.0
                    }
                ),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: subimage_bounds_subtask([subimage_anno]),
            };
            var email = get_username();
            if (email === undefined) {
                email = "Unauthenticated Stand QA User"
            }

            // Initial ULabel configuration
            const config = get_config_data(
                "container",
                this.image.rgb_url,
                email,
                subtasks,
                this.getSubmitButtons(),
                this.image,
                this.analytic,
                ulabel_initial_crop,
            )
            let ulabel = new ULabel(config);
            this.ulabel = ulabel;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                /*
                So this is absolutely horrible practice to deal with bonkers behavior 
                Basically the .on function in ulabel uses the functions .name property to reassign the callback
                For some reason vue-cli-service build sets all function.name properties to 'value'
                which breaks this callback behavior.
                Manually changing the property like this fixes it for the build version.
                */
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                )
            });
        },
    },
    beforeDestory() {
        this.beforeDestoryHandler();
    }
};
</script>

<style scoped>
#container #toolbox div.keypoint-slider div.keypoint-slider-holder {
    padding: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
}

#container #toolbox div.keypoint-slider div.keypoint-slider-holder span.increment {
    bottom: 0.5em;
}
</style>
